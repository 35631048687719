export const MenuItems = [
  {
    title: "Color Copiers",
    path: "/colorcopiers",
    cName: "dropdown-link",
  },
  {
    title: "Black and White Copiers",
    path: "/bwcopiers",
    cName: "dropdown-link",
  },
  {
    title: "Color Printers",
    path: "/clrprinter",
    cName: "dropdown-link",
  },
  {
    title: "Black and White Printers",
    path: "/bwprinter",
    cName: "dropdown-link",
  },
];
